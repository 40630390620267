<template>
  <div id="app">
    <GalleryComponent />
  </div>
</template>

<script>
import GalleryComponent from './components/GalleryComponent.vue'
import './assets/styles/gallery.css'

export default {
  components: {
    GalleryComponent,
  },
}
</script>
